// export const DS_BASE_URL = "https://localhost:44375" //DEV Local
// export const DS_BASE_URL = "https://stage-api.digmstools.com" //STAGE SERVER
export const DS_BASE_URL = "https://api.digmstools.com" // PROD SERVER
// export const DS_BASE_URL = "http://webapi.115.dev.dealerspike.net" //DEV SERVER

export const ACCURANKER_KEYWORD_ENDPOINT = "/accuranker/keywords"
export const RDS_KEYWORD_PUSH_ENDPOINT = "/public-rds/keywords/push"
export const CLIENTS_ENDPOINT = '/clients';
export const GET_ALL_PACKAGES_ENDPOINT = "/clients/packages";
export const GET_ALL_VERTICALS_ENDPOINT = "/clients/verticals";

// Authentication
export const APP_BASE_URL = 'https://seo-internal-tools.digmstools.com'; // PROD URL
// export const APP_BASE_URL = 'https://stage.seo-internal-tools.digmstools.com'; // STAGE URL
export const APP_BASE_URL_DEV = "http://localhost:3000" // DEV URL
